<template>
    <div style="width:100vw;margin:-12px;">
        <!-- app bar -->
        <top-nav title="Productos"></top-nav>
        
        <div v-if="products != null">
            <!-- content -->
            <v-list two-line>
                <template v-for="(product, ix) in products">
                    <v-list-item :key="product.id" :to="'/secure/product/' + product.id">
                        <v-list-item-avatar>
                            <v-img :src="getProductImage( product )"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="text-left">{{ product.name }}</v-list-item-title>
                            <v-list-item-subtitle class="text-left">
                                <v-chip color="primary" v-if="getProductOnSale( product )">On Sale</v-chip>
                                {{ getProductPrice( product ) }}
                            </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-avatar size="16" :color="product.state == 'draft' ? 'warning' : 'success'"></v-avatar>
                        </v-list-item-action>
                    </v-list-item>

                    <v-divider inset v-if="ix < products.length - 1" :key="product.id + '-divider'"></v-divider>
                </template>
            </v-list>

            <v-btn color="primary" dark fixed bottom right fab class="addBtn" to="/secure/product">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </div>

        <v-skeleton-loader class="mx-auto" type="list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line" v-else></v-skeleton-loader>

        <!-- bottom nav -->
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { PetuProduct } from '@/classes/product';
import { _st } from '@/softech';
import TopNav from '@/components/admin/TopNav';

export default {
    data() {
        return {
            productImages       : [],
        }
    },
    computed: {
        ...mapGetters({
            user                : 'auth/user',
            products            : 'productsInfo/products'
        }),
    },
    async mounted() {
        await this.loadProducts();
    },
    methods: {
        ...mapActions({
            getProductsData: 'productsInfo/getProductsData',
        }),
        async loadProducts() {
            await this.getProductsData();

            this.productImages = [];
            for(let p of this.products) {
                let pp = new PetuProduct( p );
                let src = await pp.getProductImage( 'small' );
                this.productImages.push({
                    id      : pp.data.id,
                    src     : src
                });
            }
            
        },
        getProductImage( product ) {
            let p = this.productImages.find(p => {
                return p.id == product.id
            });

            return p?.src;
        },
        getProductPrice( product ) {
            let p = new PetuProduct( product );
            return _st.toMoney( p.getPrice() );
        },
        getProductOnSale( product ) {
            let p = new PetuProduct( product );
            return p.isOnSale();
        },
    },
    components: {
        TopNav
    }
}
</script>

<style lang="scss" scoped>
.addBtn {
    bottom: 15px !important;
}
</style>